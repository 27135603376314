<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="全部消息" name="all">
        <div
          v-for="msg in allMegList"
          :key="msg.id"
          class="flex justify-between space-x-8 box-border p-16 border border-gray-300 rounded mb-16"
        >
          <div
            class="w-40 h-40 rounded-full bg-warning bg-opacity-30 text-warning flex justify-center items-center"
          >
            <i class="iconfont icon-fill_message text-warning text-xl"></i>
          </div>
          <div class="flex-1">
            <div class="relative">
              <span class="text-lg font-semibold">消息类型</span>
              <span
                class="w-8 h-8 rounded-full absolute right bg-warning"
              ></span>
            </div>
            <div class="text-black-100 cursor-pointer" @click="showMsg">
              华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的
            </div>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="用户消息" name="second"></el-tab-pane>
      <el-tab-pane label="系统消息" name="third"></el-tab-pane>
    </el-tabs>
    <el-dialog
      title="新增/修改地址"
      :visible.sync="dialogVisible"
      :width="'35%'"
    >
      <Message @cancle="dialogVisible = false" />
    </el-dialog>
  </div>
</template>
<script>
import Message from "./components/Message.vue";
export default {
  components: {
    Message,
  },
  data() {
    return {
      dialogVisible: false,
      activeName: "all",
      allMegList: [
        {
          id: 1,
          isRead: false,
          type: "类型1",
          content:
            "华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详址的华…",
        },
        {
          id: 2,
          isRead: true,
          type: "类型2",
          content:
            "华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详细地址的华府大道三段1900号保利星徽详址的华…",
        },
      ],
    };
  },
  methods: {
    handleClick(tab, event) {
      console.log(tab, event);
    },
    showMsg() {
      this.dialogVisible = true;
    },
  },
};
</script>
